.NewsItem-module_news-item_-OIaQ__Main {
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.NewsItem-module_news-item__published-time_m8lDw__Main {
  color: var(--text-tertiary);
  vertical-align: baseline;
  display: inline-block;
}

.NewsItem-module_news-item__sport-name_acFet__Main {
  text-align: center;
  background-color: var(--label-general-1-bg);
  width: auto;
  height: 20px;
  color: var(--label-general-1-text);
  cursor: default;
  border-radius: 5px;
  padding: 2px 5px 0;
  display: inline-block;
}

.NewsItem-module_news-item__sport-name-span_1OjyK__Main {
  vertical-align: bottom;
}

.NewsItem-module_news-item__label_oBNsg__Main {
  color: var(--text-link-active);
  margin-left: 13px;
  position: relative;
}

.NewsItem-module_news-item__label_oBNsg__Main:before {
  content: "";
  background-color: var(--icons-gray);
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 50%;
  left: -7.5px;
  transform: translateY(-50%);
}

.NewsItem-module_news-item__title-text_M6xVw__Main {
  color: var(--text-primary);
  vertical-align: baseline;
  word-break: break-word;
}

.NewsItem-module_news-item__title-text--with-caption_l65dc__Main, .NewsItem-module_news-item__text-wrapper_dqYaa__Main {
  color: var(--text-primary);
}

.NewsItem-module_news-item__info_blwrr__Main {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (max-width: 660px) {
  .NewsItem-module_news-item--show-ten-news_I1qmi__Main:nth-child(n+11) {
    display: none;
  }
}

